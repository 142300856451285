
import { Badge, Button, Card, Form, Icon, Spinner, Text, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { DiscountIcon, ChartHistogramFirstIcon, MoneyIcon } from '@shopify/polaris-icons';
import Merchants from './components/merchant/Merchants';
import TopInstalled from './components/top-installed/TopInstalled';
import { ENDPOINT } from './utils/functions.js';
import Pricing from './components/pricing/Pricing.jsx';

function App() {
    const [active, setActive] = useState('logs')
    const [account, setAccount] = useState({ username: "", password: "" })
    const [needLogin, setNeedLogin] = useState(true)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        status: true,
        text: ''
    })

    const handleChangeMenu = (value) => {
        setActive(value)
    }

    const handleChangeAccount = (value, type) => {
        setAccount(prev => ({ ...prev, [type]: value }))
    }

    const handleLogin = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${ENDPOINT}/api/login`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(account),
            });
            const result = await response.json();
            if (result.status) {
                setMessage({ status: true, text: "Login successful" })
                setNeedLogin(false)
            } else {
                setMessage({ status: false, text: "Login failed. Please try again!" })
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoading(false)
    }

    return (
        <div className="smind-app">
            {
                needLogin ? (
                    <div className='page-login'>
                        <Card>
                            <div className='_inner'>
                                <Form onSubmit={handleLogin}>
                                    <Text as='h3' variant='headingXl' alignment='center'>Smind tools</Text>
                                    <div className='mb-400'></div>
                                    <TextField
                                        label="Username"
                                        type="text"
                                        value={account.username}
                                        autoComplete='off'
                                        onChange={(value) => handleChangeAccount(value, 'username')}
                                    />
                                    <div className='mb-400'></div>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        value={account.password}
                                        autoComplete='off'
                                        onChange={(value) => handleChangeAccount(value, 'password')}
                                    />
                                    <div className='mb-400'></div>
                                    <Button submit variant='primary' fullWidth size='large' loading={loading}>Login</Button>
                                    {message.text !== '' ? (<div className='mt-200 text-align-center'><Text tone={!!message.status ? 'success' : 'critical'}>{message.text}</Text></div>) : null}
                                </Form>
                            </div>
                        </Card>
                    </div>
                ) : (
                    <div className='page-admin-tools d-flex gap-400'>

                        <div className='_sidebar p-600'>
                            <div className='mb-400'>
                                <Text as='h3' variant='headingMd'>Navigation</Text>
                            </div>
                            <div className={`item mb-200 cursor-pointer d-flex align-items-center gap-100 ${active === 'logs' ? 'active' : ''}`} onClick={() => handleChangeMenu('logs')}>
                                <Icon source={DiscountIcon} tone='base' />
                                <Text as='h3' variant='headingMd' fontWeight='medium'>Merchant logs</Text>
                            </div>
                            <div className={`item cursor-pointer d-flex align-items-center gap-100 ${active === 'top' ? 'active' : ''}`} onClick={() => handleChangeMenu('top')}>
                                <Icon source={ChartHistogramFirstIcon} tone='base' />
                                <Text as='h3' variant='headingMd' fontWeight='medium'>Top installed</Text>
                            </div>
                            {
                                account.username === 'super' ? (
                                    <div className={`item cursor-pointer d-flex align-items-center gap-100 ${active === 'pricing' ? 'active' : ''}`} onClick={() => handleChangeMenu('pricing')}>
                                        <Icon source={MoneyIcon} tone='base' />
                                        <Text as='h3' variant='headingMd' fontWeight='medium'>Pricing</Text>
                                    </div>
                                ) : null
                            }

                        </div>
                        <div className='_content'>
                            {active === 'logs' && <Merchants />}
                            {active === 'top' && <TopInstalled />}
                            {(active === 'pricing' && account.username === 'super')  && <Pricing />}
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default App;
