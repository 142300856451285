import { Badge, Button, ButtonGroup, Icon, Text, TextField } from "@shopify/polaris"
import { useCallback, useEffect, useRef, useState } from "react"
import { capitalize, debounce } from "lodash";
import { ENDPOINT, convertToAsiaTimeZone } from "../../utils/functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DuplicateIcon } from '@shopify/polaris-icons';

function Search({_s}) {
    const [searchText, setSearchText] = useState(_s)
    const [merchants, setMerchants] = useState([])
    const [action, setAction] = useState(null)
    const [childAction, setChildAction] = useState(null)
    const [readLog, setReadLog] = useState([])
    const [cssCode, setCssCode] = useState('Loading data...')
    const [jsCode, setJsCode] = useState('Loading data...')
    const [liquidCode, setLiquidCode] = useState({
        fileName: '',
        fileCode: ''
    })
    const [cssState, setCssStatus] = useState('')
    const [jsState, setJsStatus] = useState('')
    const [liquidState, setLiquidStatus] = useState('')
    const [loading, setLoading] = useState({ css: false, js: false, liquid: false })

    const delayedSearch = useCallback(
        debounce((query) => {
            handleGetMerchant(query)
        }, 250),
        []
    );

    useEffect(() => {
        if(_s !== '') {
            handleGetMerchant(_s)
        }
    }, [_s])

    const handleChangeSearchText = (value) => {
        setSearchText(value);
        delayedSearch(value);
    }

    const handleGetMerchant = async (query) => {
        try {
            const response = await fetch(`${ENDPOINT}/api/get-merchant`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _s: query
                }),
            });
            const result = await response.json();
            if (result.status) {
                setMerchants(result.data)
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const handleGetLog = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'logs' }));

        try {
            const response = await fetch(`${ENDPOINT}/api/get-logs`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    shopUrl
                }),
            });
            const result = await response.json();
            setReadLog(result.data.lines);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const textToCopyRef = useRef(null);
    const copyText = () => {
        const textToCopy = textToCopyRef.current.innerText;
        const textarea = document.createElement('textarea');
        textarea.value = "Summarize the user actions from the following log: \n" + textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Text copied to clipboard!');
    }

    const handleCustomCss = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'css' }));
        setCssCode('Loading data...')
        const response = await fetch(`${ENDPOINT}/api/get-css`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl
            }),
        });
        const result = await response.json();
        setCssCode(result.data);
    }

    const handleCustomLiquid = async (prev, index, shopUrl) => {
        setAction(({ ...prev, [index]: 'liquid' }));
    }

    const handleSaveCss = async (shopUrl) => {
        setLoading(prev => ({ ...prev, css: true }))
        const response = await fetch(`${ENDPOINT}/api/save-css`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                cssCode
            }),
        });
        const result = await response.json();
        setCssStatus(result?.message)
        setLoading(prev => ({ ...prev, css: false }))

        setTimeout(() => {
            setCssStatus('')
        }, 5000)
    }

    const handleSaveJs = async (shopUrl) => {
        setLoading(prev => ({ ...prev, js: true }))
        const response = await fetch(`${ENDPOINT}/api/save-js`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                jsCode
            }),
        });
        const result = await response.json();
        setJsStatus(result?.message)
        setLoading(prev => ({ ...prev, js: false }))

        setTimeout(() => {
            setJsStatus('')
        }, 5000)
    }

    const handleSaveLiquid = async (shopUrl) => {
        setLoading(prev => ({ ...prev, liquid: true }))
        const response = await fetch(`${ENDPOINT}/api/save-liquid`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                shopUrl,
                liquidCode
            }),
        });
        const result = await response.json();
        setLiquidStatus(result?.message)
        setLoading(prev => ({ ...prev, liquid: false }))

        setTimeout(() => {
            setLiquidStatus('')
        }, 5000)
    }

    return (
        <>
            <TextField
                placeholder="Enter your store info: Name, url, email"
                type="text"
                value={searchText}
                onChange={handleChangeSearchText}
            />
            <div className="mt-400"></div>
            {
                !merchants.length ? (
                    <div className="mt-300">
                        <Text as="h3" variant="headingMd">No merchants</Text>
                    </div>
                ) : (
                    <div className="mt-300 mb-300">
                        <Text as="h3" variant="headingMd">{merchants.length} merchants</Text>
                    </div>
                )
            }
            {
                merchants.map((_item, index) => {
                    const _action = action?.[index] ?? 'installed_sections'
                    const _childAction = childAction?.[index] ?? 'installed_sections_sections'
                    let item = _item
                    let itemTemp = _item
                    if (!!item.meta.isUninstalled && item?.oldData) {
                        item = { ...item, ...item?.oldData }
                    }
                    return (
                        <div className="_item" key={item?._id}>
                            <div className="d-flex">
                                <div className="_colleft">
                                    <Text tone="success" as="h3" variant="headingMd">
                                        <div className="d-flex gap-100">
                                            <span>INFO</span>
                                            {
                                                !!itemTemp.meta.isUninstalled ? (
                                                    <Badge tone="critical" hideIcon>
                                                        <Text alignment="justify" as="p" fontWeight="bold">UNINSTALLED</Text>
                                                    </Badge>
                                                ) : (
                                                    <Badge tone="success" hideIcon>
                                                        <Text alignment="justify" as="p" fontWeight="bold">ACTIVE</Text>
                                                    </Badge>
                                                )
                                            }
                                        </div>
                                    </Text>
                                    <p className="mt-300">
                                        Shop name<br />
                                        <b>{item?.shopName}</b>
                                    </p>
                                    <p className="mt-200">
                                        Shop URL<br />
                                        <b><a href={`https://${item?.shopUrl}`} target="_blank">{item?.shopUrl}</a></b>
                                    </p>
                                    <p className="mt-200">
                                        Shopify Plan<br />
                                        <b>{item?.meta.shopifyPlan}</b>
                                    </p>
                                    <p className="mt-200">
                                        Email<br />
                                        <b>{item?.email}</b>
                                    </p>
                                    <p className="mt-200">
                                        App Plan<br />
                                        <b>{item?.plan.name}</b>
                                    </p>
                                    {
                                        !!itemTemp.meta.isUninstalled ? (
                                            <>
                                                <p className="mt-200">
                                                    Installed at<br />
                                                    <b>{convertToAsiaTimeZone(item.createdAt)}</b>
                                                </p>
                                                <p className="mt-200">
                                                    Uninstalled at<br />
                                                    <b>{convertToAsiaTimeZone(item.updatedAt)}</b>
                                                </p>
                                            </>
                                        ) : (
                                            <p className="mt-200">
                                                Installed at<br />
                                                <b>{convertToAsiaTimeZone(item.createdAt)}</b>
                                            </p>
                                        )
                                    }
                                    {
                                        itemTemp.meta.isDeletedData ? (
                                            <p className="mt-400">
                                                <Badge tone="critical" hideIcon>
                                                    <Text alignment="justify" as="p" fontWeight="bold">IS DELETED APP DATA</Text>
                                                </Badge>
                                            </p>
                                        ) : null
                                    }
                                </div>
                                <div className="_colright">
                                    <div className="_tab">
                                        <div className="d-flex justify-space-between">
                                            <ButtonGroup variant="segmented">
                                                <Button pressed={_action === 'installed_sections'} onClick={() => {
                                                    setAction(prev => ({ ...prev, [index]: 'installed_sections' }))
                                                    setChildAction(prev => ({ ...prev, [index]: 'installed_sections_sections' }))
                                                }}>
                                                    <span className="pr-100">Installed</span>
                                                    <Badge tone="info">{item.myLibrary.sections.length + item.myLibrary.globalStyles.length + item.myLibrary.templates.length}</Badge>
                                                </Button>
                                                <Button pressed={_action === 'paid_sections'} onClick={() => {
                                                    setAction(prev => ({ ...prev, [index]: 'paid_sections' }))
                                                    setChildAction(prev => ({ ...prev, [index]: 'paid_sections_sections' }))
                                                }}>
                                                    <span className="pr-100">Paid</span>
                                                    <Badge tone="info">{item.myLibrary.paid.sections.length}</Badge>
                                                </Button>
                                                
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                <Button variant={_action === 'liquid' ? 'primary' : 'secondary'} onClick={(prev) => handleCustomLiquid(prev, index, item?.shopUrl)}>Liquid</Button>
                                                <Button variant={_action === 'css' ? 'primary' : 'secondary'} onClick={(prev) => handleCustomCss(prev, index, item?.shopUrl)}>Css</Button>
                                                <Button variant={_action === 'logs' ? 'primary' : 'secondary'} onClick={(prev) => handleGetLog(prev, index, item?.shopUrl)}>Logs</Button>
                                                {
                                                    _action === 'logs' ? (
                                                        <Button primary onClick={copyText}
                                                            prefix={
                                                                <Icon
                                                                    source={DuplicateIcon}
                                                                    tone="base"
                                                                />
                                                            }
                                                        >
                                                            Copy
                                                        </Button>
                                                    ) : null
                                                }
                                            </ButtonGroup>

                                        </div>
                                        <div className="_tab-content">
                                            {
                                                _action === 'css' ? (
                                                    <>
                                                        <>
                                                        <div className="css-records mb-400">
                                                            <TextField
                                                                label="Css code"
                                                                value={cssCode}
                                                                onChange={value => setCssCode(value)}
                                                                multiline={12}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center gap-300">
                                                            <Button variant="primary" loading={loading?.css} onClick={() => handleSaveCss(item?.shopUrl)}>Save CSS</Button>
                                                            {cssState ? <Text>{cssState}</Text> : ''}
                                                        </div>
                                                        </>

                                                        <>
                                                            <div className="css-records mb-400">
                                                                <TextField
                                                                    label="Js code"
                                                                    value={jsCode}
                                                                    onChange={value => setJsCode(value)}
                                                                    multiline={12}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className="d-flex align-items-center gap-300">
                                                                <Button variant="primary" loading={loading?.js} onClick={() => handleSaveJs(item?.shopUrl)}>Save JS</Button>
                                                                {jsState ? <Text>{jsState}</Text> : ''}
                                                            </div>
                                                        </>
                                                    </>
                                                ) : null
                                            }

                                            {
                                                _action === 'liquid' ? (
                                                    <>
                                                        <div className="css-records mb-400">
                                                            <div className="mb-300">
                                                                <TextField
                                                                    label="File name"
                                                                    placeholder="sections/smi-featured-collection-1-1.liquid"
                                                                    value={liquidCode.fileName}
                                                                    onChange={value => setLiquidCode(prev => ({ ...prev, fileName: value }))}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <TextField
                                                                label="File code"
                                                                placeholder="{%- assign demo = settings.demo_variable -%}"
                                                                value={liquidCode.fileCode}
                                                                onChange={value => setLiquidCode(prev => ({ ...prev, fileCode: value }))}
                                                                multiline={12}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center gap-300">
                                                            <Button variant="primary" loading={loading?.liquid} onClick={() => handleSaveLiquid(item?.shopUrl)}>Save</Button>
                                                            {liquidState ? <Text>{liquidState}</Text> : ''}
                                                        </div>
                                                    </>
                                                ) : null
                                            }

                                            {
                                                _action === 'logs' ? (
                                                    <div className="logs-records" ref={textToCopyRef}>
                                                        {
                                                            readLog.length ? readLog.map((log, idx) => {
                                                                return (
                                                                    <div className="_list-item" key={`logs-${idx}`}>
                                                                        {log}<br />
                                                                    </div>
                                                                )
                                                            }) : 'No data!'
                                                        }

                                                    </div>
                                                ) : null
                                            }

                                            {
                                                _action === 'installed_sections' ? (
                                                    <div className="_tab-content-installed_sections">
                                                        <ButtonGroup variant="segmented">
                                                            <Button pressed={_childAction === 'installed_sections_sections'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_sections' }))}>
                                                                <span className="mr-200">Sections</span>
                                                                <Badge tone="info">{item.myLibrary.sections.length}</Badge>
                                                            </Button>
                                                            <Button pressed={_childAction === 'installed_sections_global_styles'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_global_styles' }))}>
                                                                <span className="mr-200">Styles</span>
                                                                <Badge tone="info">{item.myLibrary.globalStyles.length}</Badge>
                                                            </Button>
                                                            <Button pressed={_childAction === 'installed_sections_templates'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'installed_sections_templates' }))}>
                                                                <span className="mr-200">Templates</span>
                                                                <Badge tone="info">{item.myLibrary.templates.length}</Badge>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <div className="mb-300"></div>

                                                        {
                                                            _childAction === 'installed_sections_sections' ? (
                                                                <div className="_tab-content-list">
                                                                    {
                                                                        item.myLibrary.sections.length ? item.myLibrary.sections.map((section, idx) => {
                                                                            return (
                                                                                <div className="_list-item" key={`installed_sections_sections-${idx}`}>
                                                                                    <div className='item-thumbnail'>
                                                                                        <LazyLoadImage
                                                                                            effect="opacity"
                                                                                            src={`https://app.smind.io/libraries/sections/${section?.core}/presets/${section.preset.name}/${section.preset.style}/${section.preset.industry}/thumbnail.png`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='item-name'>
                                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">{section.name}</Text>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : 'No data!'
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }

                                                        {
                                                            _childAction === 'installed_sections_global_styles' ? (
                                                                <div className="_tab-content-list">
                                                                    {
                                                                        item.myLibrary.globalStyles.length ? item.myLibrary.globalStyles.map((section, idx) => {
                                                                            return (
                                                                                <div className="_list-item" key={`installed_sections_sections-${idx}`}>
                                                                                    <div className='item-thumbnail'>
                                                                                        <LazyLoadImage
                                                                                            effect="opacity"
                                                                                            src={`https://app.smind.io/libraries/styles/${section?.handle}/thumbnail.png`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='item-name'>
                                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">{section.name}</Text>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : 'No data!'
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }

{
                                                            _childAction === 'installed_sections_templates' ? (
                                                                <div className="_tab-content-list">
                                                                    {
                                                                        item.myLibrary.templates.length ? item.myLibrary.templates.map((template, idx) => {
                                                                            return (
                                                                                <div className="_list-item" key={`installed_sections_templates-${idx}`}>
                                                                                    <div className='item-thumbnail item-thumbnail--template'>
                                                                                        <LazyLoadImage
                                                                                            effect="opacity"
                                                                                            src={`https://app.smind.io/libraries/templates/${template?.handle}/thumbnail.png`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='item-name item-name--template'>
                                                                                        <p>Core: <Text variant="bodyMd" fontWeight="bold" as="span">{template.handle}</Text></p>
                                                                                        <p>
                                                                                            { 
                                                                                               !!template.meta.createPage ? (
                                                                                                <>
                                                                                                Page: <a href={`https://${item?.shopUrl}/pages/${template.meta.pageHandle}`} target="_blank">{template.meta.pageName}</a>
                                                                                                </>
                                                                                            ) : null
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : 'No data!'
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                ) : null
                                            }

                                            {
                                                _action === 'paid_sections' ? (
                                                    <div className="_tab-content-installed_sections">
                                                        <ButtonGroup variant="segmented">
                                                            <Button pressed={_childAction === 'paid_sections_sections'} onClick={() => setChildAction(prev => ({ ...prev, [index]: 'paid_sections_sections' }))}>
                                                                <span className="mr-200">Sections</span>
                                                                <Badge tone="info">{item.myLibrary.paid.sections.length}</Badge>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <div className="mb-300"></div>

                                                        {
                                                            _childAction === 'paid_sections_sections' ? (
                                                                <div className="_tab-content-list">
                                                                    {
                                                                        item.myLibrary.paid.sections.length ? item.myLibrary.paid.sections.map((section, idx) => {
                                                                            return (
                                                                                <div className="_list-item" key={`paid_sections_sections-${idx}`}>
                                                                                    <div className='item-thumbnail'>
                                                                                        <LazyLoadImage
                                                                                            effect="opacity"
                                                                                            src={`https://app.smind.io/libraries/sections/${section?.core}/presets/${section.preset.name}/${section.preset.style}/${section.preset.industry}/thumbnail.png`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='item-name'>
                                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">{section.section.name}</Text>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : 'No data!'
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }

                                                        {
                                                            _childAction === 'paid_sections_global_styles' ? (
                                                                <div className="_tab-content-list">
                                                                    {
                                                                        item.myLibrary.paid.globalStyles.length ? item.myLibrary.paid.globalStyles.map((section, idx) => {
                                                                            return (
                                                                                <div className="_list-item" key={`paid_sections_sections-${idx}`}>
                                                                                    <div className='item-thumbnail'>
                                                                                        <LazyLoadImage
                                                                                            effect="opacity"
                                                                                            src={`https://app.smind.io/libraries/styles/${section?.handle}/thumbnail.png`}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='item-name'>
                                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">{section.name}</Text>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : 'No data!'
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                ) : null
                                            }

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}

export default Search