import { Button, ButtonGroup, Card, Page, Spinner, Text } from "@shopify/polaris"
import { useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { ENDPOINT } from "../../utils/functions"

function TopInstalled(props) {
    const [sections, setSections] = useState([])
    const [templates, setTemplates] = useState([])
    const [tab, setTab] = useState('section')

    useEffect(() => {
        const getSections = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/api/get-top-sections`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const result = await response.json();

                if (result.status) {
                    setSections(result.sections)
                    setTemplates(result.templates)
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }

        getSections()
    }, [])

    return (
        <div className="admin-merchant-logs-page p-relative">
            <Page title="Top installed" >
                <Card>
                    <div className="_tab">
                        <div className="d-flex justify-space-between">
                            <ButtonGroup variant="segmented">
                                <Button pressed={tab === 'section'} onClick={() => setTab('section')}><span className="pr-100">Section</span></Button>
                                <Button pressed={tab === 'template'} onClick={() => setTab('template')}><span className="pr-100">Template</span></Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="all-merchants">
                        <table className="mt-300">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Counter</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!sections.length ? (
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="d-flex justify-center w-100"><Spinner size="large" /></div>
                                        </td>
                                    </tr>
                                ) : null}


                                {
                                    tab === 'section' ? sections.map((section, idx) => {
                                        let _arr = section.handle.split('-')
                                        let _preset = _arr[_arr.length - 1]
                                        return (
                                            <tr key={section._id}>
                                                <td style={{ width: '100px' }}>
                                                    <Text as="p" variant="headingMd">#{idx + 1}</Text>
                                                </td>
                                                <td style={{ maxWidth: '100px' }}>
                                                    <div className='item-thumbnail'>
                                                        <LazyLoadImage
                                                            effect="opacity"
                                                            src={`https://app.smind.io/libraries/sections/${section?.core}/presets/preset-${_preset}/classic-1/clothing/thumbnail.png`}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <Text as="p" variant="headingMd">{section.name}</Text>
                                                </td>
                                                <td>
                                                    <Text as="p" variant="headingXl">{section.numberInstalled}</Text>
                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }

                                {
                                    tab === 'template' ? templates.map((template, idx) => {
                                        return (
                                            <tr key={template._id}>
                                                <td style={{ width: '100px' }}>
                                                    <Text as="p" variant="headingMd">#{idx + 1}</Text>
                                                </td>
                                                <td>
                                                    <div className='item-thumbnail item-thumbnail--template' style={{minWidth: '115px'}}>
                                                        <LazyLoadImage
                                                            effect="opacity"
                                                            src={`https://app.smind.io/libraries/templates/${template?.handle}/thumbnail.png`}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <Text as="p" variant="headingMd">{template.name}</Text>
                                                </td>
                                                <td>
                                                    <Text as="p" variant="headingXl">{template.numberInstalled}</Text>
                                                </td>
                                            </tr>
                                        )
                                    }) : null
                                }

                            </tbody>
                        </table>
                    </div>
                </Card>
            </Page>
        </div>
    )
}

export default TopInstalled